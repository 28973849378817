import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import { commonStyles } from '../../_common_styles'

export const ComponentWrapper = styled.div`
  max-width: 250px;

  @media ${device.tablet} {
    max-width: 350px;
  }

  @media ${device.desktopLG} {
    max-width: 400px;
  }

  @media ${device.desktopXXL} {
    max-width: 517px;
  }
`

export const Title = styled.p`
  font-family: Citrina, sans-serif, Arial;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: white;

  margin: 0;
  align-items: center;

  @media ${device.tablet} {
    font-size: 24px;
    line-height: 29px;
  }

  @media ${device.desktopLG} {
    font-size: 28px;
    line-height: 34px;
  }
`

export const Description = styled.p`
  font-family: Inter, sans-serif, Arial;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: white;

  margin: 4px 0 0 0;

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 17px;
  }
`

export const TextWrapper = styled.div`
  height: 75px;
  background-color: ${commonStyles.customBlack};

  border-radius: 0 0 19px 19px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    height: 100px;
  }

  @media ${device.desktopXXL} {
    height: 116px;
  }
`

export const CardImage = styled.picture``

export const CardImageSource = styled.img`
  width: 100%;
`

