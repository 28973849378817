import React from 'react'

import * as S from './_styles'

type IconAndDescriptionProps = {
  icon: JSX.Element;
  description: string;
  className?: string;
  iconClassName?: string;
}

const IconAndDescription = ({
  icon,
  description,
  className,
  iconClassName,
}: IconAndDescriptionProps) => {
  return (
    <S.ComponentWrapper
      className={className}
    >
      <S.Icon className={iconClassName}>
        {icon}
      </S.Icon>
      <S.Description
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </S.ComponentWrapper>
  )
}

export default IconAndDescription
