import styled from 'styled-components'
import { commonStyles } from '../../_common_styles'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled(commonStyles.sectionWrapper)`
  
  @media ${device.tablet} {}
  @media ${device.desktopLG} {}
  @media ${device.desktopXXL} {}
  
  .carousel__container {
    overflow: visible;
  }

  .know_more {
    width: 50%;
    display: flex;
    margin: 24px auto 0 auto;

    @media ${device.desktopLG} {
      margin-top: 40px
    }
  }
`

export const Title = styled(commonStyles.sectionSecondaryTitle)`
  text-align: center;

  @media ${device.desktopLG} {
    margin-bottom: 10px;
  }
`
