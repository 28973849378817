import styled from 'styled-components'
import { commonStyles } from '../../_common_styles'
import { device } from 'src/styles/breakpoints'

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .apartments_card__description__item {
    margin: 12px 0;
    
    @media ${device.desktopXXL} {
      width: 30%;
    }

    &__icon {
      margin-right: 12px;

      &__source {
        height: 24px;
        width: 24px;

        @media ${device.tablet} {
          width: 28px;
          height: 28px;
        }

        @media ${device.desktopLG} {
          width: 32px;
          height: 32px;
        };
      }
    }
  }
`

export const Texts = styled.div`
  background-color: ${commonStyles.customBlack};
  padding: 24px;

  border-radius: 0 0 24px 24px;
`

export const Title = styled.h3`
  color: ${commonStyles.sandColor};
  font-family: Citrina, sans-serif, Arial;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin: 0;

  @media ${device.tablet} {
    font-size: 28px;
    line-height: 34px;
  }

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 44px;
  }
`

export const Description = styled.p`
  margin: 24px 0;

  @media ${device.desktopLG} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

export const ApartmentImage = styled.picture`
`

export const ApartmentImageSource = styled.img`
  width: 100%;
  height: auto;
`
