import React from "react"

import BaseLayout from "src/layouts/BaseLayout"
import { initTheme, Theme } from "@interco/inter-ui"

import pageContext from "./pageContext.json"

import S1_JeitoInteligenteDeMorar from './sections/s1-jeito-inteligente-de-morar/_index'
import S2_TodaInovacaoEPraticidade from './sections/s2-toda-inovacao-e-praticidade/_index'
import S3_MaisQueUmApartamento from './sections/s3-mais-que-um-apartamento/_index'
import S4_MuitasPossibilidadesPraVoce from './sections/s4-muitas-possibilidades-pra-voce-escolher/_index'
import S5_QuemEInderResidence from './sections/s5-quem-e-inter-residence/_index'

const InterResidence = () => {
  React.useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <BaseLayout pageContext={pageContext}>
      <S1_JeitoInteligenteDeMorar />
      <S2_TodaInovacaoEPraticidade />
      <S3_MaisQueUmApartamento />
      <S4_MuitasPossibilidadesPraVoce />
      <S5_QuemEInderResidence />
    </BaseLayout>
  )
}

export default InterResidence
