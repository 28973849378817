import React from 'react'
import * as S from './_styles'

const JeitoInteligenteDeMorar = () => {
  return (
    <S.SectionWrapper id='o-jeito-inteligente-de-morar'>
      <div className='container'>
        <S.Row className='row'>
          <div className='col-12 col-md-6 order-md-2 col-xl-5'>
            <S.HeroImage>
              <source
                srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/residence-hero-1440/image.webp'
                media='(min-width: 768px)'
              />
              <S.HeroImageSource
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/residence-hero-360/image.webp'
                alt='homem e mulher fechando caixa de papelão para mudança'
              />
            </S.HeroImage>
          </div>
          <S.Content className='col-12 col-md-4 order-md-1 col-lg-5 col-xl-6'>
            <h1>
              <S.InterResidenceLogo
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/logo-inter-residence/image.webp'
                alt='Inter Residence. O jeito inteligente de morar.'
              />
            </h1>
            <S.Title>
              O jeito inteligente de morar.
            </S.Title>
          </S.Content>
        </S.Row>
      </div>
    </S.SectionWrapper>
  )
}

export default JeitoInteligenteDeMorar
