import { device } from 'src/styles/breakpoints'
import * as newColors from 'src/styles/newColors'
import styled from 'styled-components'

export const commonStyles = {
  sectionWrapper: styled.section`
    background-color: ${newColors.graphite};
    overflow-x: hidden;
  
    padding: 40px 0;
    
    @media ${device.tablet} {
      padding: 56px 0;
    }
    
    @media ${device.desktopLG} {
      padding: 64px 0;
    }
    
    @media ${device.desktopXXL} {
      padding: 96px 0;
    }
  `,

  sectionSecondaryTitle: styled.h2`
    color: ${newColors.primary[500]};
    font-family: Citrina, sans-serif, Arial;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    
    margin: 0;

    @media ${device.tablet} {
      font-size: 40px;
      line-height: 44px;
    }

    @media ${device.desktopLG} {
      font-size: 48px;
      line-height: 53px;
    }
  `,

  sandColor: '#FCF8EE',

  customBlack: '#1C1C1C',

  sectionWithPadding: styled.section`
    padding-right: 24px;
    padding-left: 24px;

    @media ${device.tablet} {
      padding-right: 36px;
      padding-left: 36px;
    }
    
    @media ${device.desktopLG} {
      padding-right: 44px;
      padding-left: 44px;
    }
    
    @media ${device.desktopXXL} {
      padding-right: 156px;
      padding-left: 156px;
    }
  `,
}
