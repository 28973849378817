import React from 'react'

import * as S from './_styles'
import * as T from './_types'
import apartments from './apartments.json'

import ApartmentQualitiesCard from '../../components/ApartmentQualitiesCard/_index'

const MuitasPossibilidadesPraVoce = () => {
  return (
    <S.SectionWrapper id='muitas-possibilidades-pra-voce-escolher-um-apartamento'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <S.Title>
              Muitas possibilidades pra você escolher um apartamento que é a sua cara.
            </S.Title>
            <S.ApartmentCardsWrapper>
              { apartments.map((apartment: T.ApartmentCardProps, index: number) => (
                <ApartmentQualitiesCard
                  key={apartment.title}
                  apartmentName={apartment.title}
                  apartmentDescription={ apartment.description }
                  apartmentImage={apartment.image}
                  linkForMoreDetails={apartment.link}
                  dataLayer={{
                    section: 'dobra_04',
                    section_name: 'Muitas possibilidades pra você escolher um apartamento que é a sua cara.',
                  }}
                  className={`col-12 col-md-6 apartments_card--${index}`}
                />
              ))}
            </S.ApartmentCardsWrapper>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default MuitasPossibilidadesPraVoce
