import React from 'react'

import * as S from './_styles'
import benefits from './benefits.json'
import { commonStyles } from '../../_common_styles'

import IconAndDescription from '../../components/IconAndDescription/_index'
import IconsSwitch from 'src/components/IconsSwitch/_index'

type BenefitType = {
  title: string;
  icon: {
    name: string;
    customPath: string;
  };
}

const QuemEInderResidence = () => {
  return (
    <S.SectionWrapper id='quem-e-inter-residence-tem-sempre-mais'>
      <div className='container'>
        <S.Row className='row'>
          <S.AppAndCardImage className='col-12 col-md-6 col-lg-5'>
            <S.AppAndCardImageSource
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/mockup-residence/image.webp'
              alt='tela de celular mostrando a home do super app Inter, sobreposta por uma imagem do cartão black Inter'
            />
          </S.AppAndCardImage>
          <S.Texts className='col-12 col-md-6'>
            <S.Title>
              Quem é Inter Residence, tem sempre mais.
            </S.Title>
            <S.Description>
              Os moradores também podem aproveitar os benefícios de ser Inter. Seja na hora de se divertir, durante uma viagem ou nas compras, com o Inter você descobre o jeito inteligente de viver o melhor da vida.
            </S.Description>
            <S.BenefitsList>
              {benefits.map((benefit: BenefitType) => (
                <S.Benefit key={benefit.title}>
                  <IconAndDescription
                    description={benefit.title}
                    className='benefits_list__item'
                    iconClassName='benefits_list__item__icon'
                    icon={
                      <IconsSwitch
                        icon={benefit.icon.name}
                        customPath={benefit.icon.customPath}
                        size='MD'
                        lib='interco'
                        color={commonStyles.sandColor}
                        className='benefits_list__item__icon__source'
                      />
                    }
                  />
                </S.Benefit>
              ))}
            </S.BenefitsList>
          </S.Texts>
        </S.Row>
      </div>
    </S.SectionWrapper>
  )
}

export default QuemEInderResidence
