import React from 'react'

import * as S from './_styles'
import * as T from './_types'
import carouselCards from './carouselCards.json'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import CardWithImage from '../../components/CardWithImage/_index'
import { primary } from 'src/styles/newColors'
import { Button } from '@interco/inter-ui/components/Button'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const MaisQueUmApartamento = () => {
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.SectionWrapper id='mais-que-um-apartamento'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <S.Title>
              Mais que um apartamento,<br />
              um projeto pensado pra sua vida.
            </S.Title>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 38 }}
              md={{ items: 1, partialVisibilityGutter: 310 }}
              lg={{ items: 2, partialVisibilityGutter: 40 }}
              xl={{ items: 2, partialVisibilityGutter: 20 }}
              containerClass='carousel__container'
              customDotColor={primary[500]}
            >
              {carouselCards.map((card: T.CarouselCard) => (
                <CardWithImage
                  key={card.title}
                  title={card.title}
                  image={card.image}
                  alt={card.alt}
                  description={card.description}
                />
              ))}
            </DefaultCarousel>
            <Button
              as='a'
              href='#muitas-possibilidades-pra-voce-escolher-um-apartamento'
              className='know_more'
              onClick={() => sendDataLayer({
                section: 'dobra_03',
                section_name: 'Mais que um apartamento, um projeto pensado para sua vida',
                element_name: 'Saiba mais',
                element_action: 'click button',
                redirect_url: '#muitas-possibilidades-pra-voce-escolher-um-apartamento',
              })}
            >
              Saiba mais
            </Button>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default MaisQueUmApartamento
