import styled from 'styled-components'

import { commonStyles } from '../../_common_styles'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled(commonStyles.sectionWrapper)`
  padding: 56px 0;
  .apartments_card--0 {
      margin-bottom: 32px;

      @media ${device.tablet} {
        margin-bottom: 0;
      }
  } 
`

export const Title = styled(commonStyles.sectionSecondaryTitle)`
  text-align: center;
  margin-bottom: 24px;
  padding: 0 5%;

  @media ${device.tablet} {
    padding: 0;
  }
  
  @media ${device.desktopLG} {
    margin-bottom: 40px;
  }
`

export const ApartmentCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
