import React from 'react'
import * as S from './_styles'
import useDomReady from 'src/hooks/window/useDomReady'

type CardWithImageProps = {
  title: string;
  image: {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
  description?: string;
  alt: string;
}

const CardWithImage = ({
  title,
  image,
  description,
  alt,
}: CardWithImageProps) => {
  const domReady = useDomReady()

  return (
    <S.ComponentWrapper>
      <S.CardImage>
        {domReady && image.xl && <source srcSet={image.xl} media='(min-width: 1440px)' />}
        {domReady && image.lg && <source srcSet={image.lg} media='(min-width: 1024px)' />}
        {domReady && image.md && <source srcSet={image.md} media='(min-width: 768px)' />}
        <S.CardImageSource src={image.sm} alt={alt} />
      </S.CardImage>
      <S.TextWrapper>
        <S.Title>
          {title}
        </S.Title>
        { description && (
          <S.Description>
            {description}
          </S.Description>
        )}
      </S.TextWrapper>
    </S.ComponentWrapper>
  )
}

export default CardWithImage
